import React, { useEffect, useRef } from "react"
import Image from "./Image"



export default function ImageSlider({ images, selected }) {
    const ref = useRef()

    useEffect(() => {
        const width = ref.current.clientWidth
        console.log(selected)
        ref.current.scrollLeft = width * images.indexOf(selected)
    }, [images, selected])

    return (
        <div ref={ref} style={{ scrollSnapType: 'x mandatory' }} className="flex flex-row overflow-x-scroll">
            {images.map((image) => <div style={{ scrollSnapAlign: 'start' }} className="w-full h-auto flex-shrink-0 sm:h-full sm:w-auto flex justify-center items-center">
                <Image id={image} key={image} name={image} className="w-full h-auto sm:h-full sm:w-auto" />
            </div>)}
        </div>
    )
}