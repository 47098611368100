import React from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { useMediaQuery } from '../hooks'

Modal.defaultStyles.content = {
    position: 'absolute',
    outline: 'none',
}

export default function ImageViewerModal({ isOpen, onRequestClose, children }) {
    const isMobile = useMediaQuery('(max-width: 500px)')
    const modalContentStyle = isMobile
        ? {
            left: '10px',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)'
        }
        : {
            top: '10px',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
        }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: { zIndex: 100, backgroundColor: 'rgba(0, 0, 0, .8)', backdropFilter: 'blur(4px)' },
                content: modalContentStyle
            }}
        >
            {children}
        </Modal>
    )
}
