import Image from "./Image";


export default function Footer() {
    return (
        <div className="flex flex-col-reverse items-center gap-8 sm:gap-0 sm:items-stretch sm:flex-row" id="footer">
            <div className="w-4/6 sm:flex-1"><Image name="static/86c8f8f4-1737-4185-8ba8-6a608efb8e19.jpg" /></div>
            <div className="text-courtens-lightgray bg-courtens-gray w-full sm:flex-1 flex flex-col justify-between">
                <div className="flex-1 flex flex-col gap-8 justify-around items-center text-center py-10">
                    <div>
                        <h3 className="mb-2 sm:mb-4 text-2xl sm:text-4xl">Contact Artcourtens</h3>
                        <p className="sm:text-2xl">+33 6 61 10 02 11</p>
                        <p className="sm:text-2xl">info@artcourtens.com</p>
                    </div>
                    <div>
                        <h3 className="mb-2 sm:mb-4 text-2xl sm:text-4xl">Suivez nous</h3>
                        <div className="flex justify-center gap-8">
                            <a href="https://instagram.com/jacquescourtens.artcourtens" target="_blank" rel="noreferrer">
                                <div className="w-10 transform transition hover:scale-105">
                                    <Image name="instagram.svg" local />
                                </div>
                            </a>
                            <a href="https://pinterest.com/artcourtens" target="_blank" rel="noreferrer">
                                <div className="w-10 transform transition hover:scale-105">
                                    <Image name="pinterest.svg" local />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="text-xs text-gray-400">
                    <hr />
                    <p className="pt-2 px-5">&copy; Copyright 2022 | ArtCourtens | Jacques Courtens | All Rights Reserved</p>
                    <p className="px-5 pb-2 font-semibold">Conception du site internet : Evrard CALLOT</p>
                </div>
            </div>
            <div className="w-4/6 sm:flex-1"><Image name="static/25f5b823-df2e-475e-a632-e21c61fee3db.jpg" /></div>
        </div>
    )
}