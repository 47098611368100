import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import { useMediaQuery } from "../hooks"
import Image from "./Image"
import Logo from "./Logo"
import { useState } from 'react'



export default function Navbar() {
    const isMobile = useMediaQuery('(max-width: 500px)')
    const [showMobileNav, setShowMobileNav] = useState(false)

    const closeMobileNav = () => isMobile && setShowMobileNav(false)

    const desktopNavbar = (
        <ul className="flex flex-col justify-center sm:flex-row sm:justify-end h-full">
            <NavbarItem onClick={closeMobileNav} name="L'artiste" href="/#artiste" />
            <NavbarItem onClick={closeMobileNav} name="œuvres" href="/#oeuvres" />
            {/* <NavbarItem onClick={closeMobileNav} name="Visibilité" href="/#visibilite" /> */}
            <NavbarItem onClick={closeMobileNav} name="Médias" href="/#medias" />
            <NavbarItem onClick={closeMobileNav} name="Contact" href="/#footer" />
        </ul>
    )

    const mobileNavbar = (
        <>
            <div onClick={() => setShowMobileNav(true)}>
                <Image name="menu.svg" local />
            </div>
            {showMobileNav
                ? <div className="fixed inset-0 bg-white">
                    <div onClick={() => setShowMobileNav(false)}>
                        <Image className="absolute top-6 right-4" name="close.svg" local />
                    </div>
                    {desktopNavbar}
                </div>
                : null
            }
        </>
    )

    return (
        <>
            <header className="fixed top-0 left-0 right-4 h-20 z-10 bg-white flex flex-row justify-between items-center">
                <Link to="/">
                    <div className="mx-8 w-56"><Logo /></div>
                </Link>
                {isMobile ? mobileNavbar : desktopNavbar}
            </header>
            <div className="mb-20"></div>
        </>
    )
}

function NavbarItem({ name, href, onClick }) {
    return (
        <div onClick={onClick} className="flex justify-center items-center h-12 sm:h-full hover:text-white hover:bg-courtens-blue transition">
            <li className="uppercase mx-8"><HashLink to={href}>{name}</HashLink></li>
        </div>
    )
}