import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { getThematiques } from "../Api/thematiques"
import { getPress } from "../Api/press"
import Footer from "../Components/Footer"
import Image from "../Components/Image"
import ImageCarousel from "../Components/ImageCarousel"
import ImageOverlay from "../Components/ImageOverlay"
import ImageViewerModal from "../Components/ImageViewerModal"
import Logo from "../Components/Logo"
import Page from "../Components/Page"
import { useIsMobile } from '../hooks'
import ShowMore from "../Components/ShowMore"


export default function HomePage() {
    return (
        <div>
            <Page>
                <Artiste />
                <div className="mb-52"></div>
                <RealisationsSection />
                <div className="mb-52"></div>
                {/* <VisibiliteSection /> */}
                <div className="mb-52"></div>
                <MediaSection />
                <div className="mb-52"></div>
                <Footer />
                <div className="mb-80"></div>
            </Page>
        </div>
    )
}

function Artiste() {
    const isMobile = useIsMobile()

    const biography = (
        <>
            <p>En 1940, il a 14 ans ; la guerre vient d'éclater et c'est l'exode. Il se retrouve avec son frère André et leur mère à Nice et s'installent au Negresco. Jacques assiste aux cours de l'Académie des Arts Décoratifs. Partout, toujours, on le trouve un crayon ou un pinceau à la main.</p>
            <p>Le dimanche, il va souvent travailler à Grasse, "à la recherche de la lumière de Fragonard".</p>
            <p>Était-ce un coup du destin ? Vingt ans plus tard, alors qu'il vit à nouveau à Bruxelles, il échange un tableau contre un terrain à Grasse.</p>
            <p>En 1970, son épouse Isabelle et lui-même décident de construire sur ce terrain une maison qui abritera ses fantasmes et où il pourra donner libre cours à l'expression d'un monde imaginaire qu'il a soigneusement nourri en lui au cours de nombreux périples.</p>
            <p>Trois ans plus tard, en 1973, il peint sur les murs et plafonds de cette maison des scènes visionnaires extraordinaires.</p>
            <p className="font-semibold">Il n'aura que quinze ans à vivre dans ce Midi qui lui est si cher. Il passe l'essentiel de ses jours et de ses nuits dans son atelier tandis que son épouse et muse, Isabelle s'affaire à donner à leur demeure une vie propice à ses aspirations artistiques et à recevoir leurs nombreux amis et admirateurs.</p>
            <div>
                <p>- 1940 – la guerre, il a 14 ans et c'est l'exode. Avec sa mère et son frère André, ils arrivent à Nice et s'installent au Négresco. Jacques suit les cours de l'Ecole des Arts Décoratifs, le dimanche il se rend à Grasse “à la recherche de la lumière de Fragonard”.</p>
                <p>- 1945 – il se rend en Normandie, dessine le débarquement. Fin de la guerre, retour à Bruxelles. Longs séjours à Paris où il peint avec son frère Pierre et organise la première exposition importante sous le pseudonyme “Les frères Suros” (1947). L'année suivante il peint à Amsterdam.</p>
                <p>- 1950 – l'année mariale. Se rend à pied à Rome et consacre l'année à l'étude des anciens. Se fait enfermer une nuit dans la chapelle Sixtine afin d'analyser l'œuvre de Michel Ange ! Il vit de la vente de ses dessins.</p>
                <p>- Les années 50 il peint à Bruxelles – vie de bohême ; achète et vend de la peinture contemporaine, Magritte notamment, afin de subsister dans l'authenticité.</p>
                <p>- 1951 – Organise l'exposition "Visages Présents" à Knocke le Zoute en Belgique, exposition de groupe dont E. Goerg, A. Herbin, M. Kisling, A. Lhote, C. Permeke, V. Servrank, J. Villon, H. de Waroquier, et à la Galerie Appolo à Bruxelles.</p>
                <p>- 1959 – Il se lance à la découverte de l'Espagne dont l'art médiéval le fascine tout particulièrement. L'Espagne va l'enrichir intellectuellement et matériellement, ses fréquentes expéditions en camionnette le font entrer de plain-pied dans la vie espagnole, sa culture, son art. Les gitans le baptisent affectueusement “el loco”, la haute société apprécie sa nature de “caballero” ; tous cèdent volontiers l'objet qui semble d'ailleurs s'offrir spontanément à cet amoureux fou ! À Bruxelles, avec sa seconde épouse Isabelle ; il ouvre une petite “boutique-théâtre” au 50 rue de Ruysbroeck qui devient très vite le lieu de rendez-vous des intellectuels et artistes.</p>
                <p>- 1963 – La construction du musée d'Art Moderne oblige le transfert au 20, rue E. Allard : toujours le Grand Sablon mais les locaux, plus vastes, permettent aussi l'exposition de ses œuvres.</p>
                <p>- 1970 – Il entreprend à Grasse, sur un terrain échangé contre un tableau, la construction d'une maison dessinée par lui en fonction de l'espace et lumière nécessaires à sa peinture.</p>
                <p className="font-semibold mt-5">À Grasse dès 1973 et jusqu'à son décès en 1988, il consacre chaque instant de ses quinze dernières années de vie à la réflexion, à la création et, en nous laissant une œuvre dense, satisfait à ce qu'il sait être sa mission : rendre aux générations qui suivent une part de ce bonheur si largement reçu de l'œuvre d'art.</p>
            </div>
        </>
    )

    return (
        <>
            <div id="artiste"></div>
            <div className="hidden sm:block w-96 my-10 mx-auto">
                <Logo />
            </div>
            <div className="w-96 mx-auto mb-8"><Image name="static/2c2f89a4-0cd2-420b-9eb9-79f851ea1bde.jpg" /></div>
            <div style={{ fontSize: '1.1rem' }} className="flex flex-col gap-5 text-justify mb-32">
                <p className="sm:font-semibold">Jacques Courtens nait à Bruxelles en 1926 de mère Hollandaise dans une famille d'artistes, peintres, sculpteur et architectes de grand renom.</p>
                <p className="sm:font-semibold">Son père Hermann, peintre et professeur à l'Académie des Beaux-Arts d'Anvers, était le fils de Franz Courtens, Peintre à la Cour de Belgique dont la réputation s'est étendu non seulement dans toute l'Europe mais jusqu'en Asie. Enfant, les yeux grands ouverts, le petit Jacques passe des journées entières dans leur atelier.</p>
                <p className="sm:font-semibold">On s'étonne donc peu que c'est par le dessin et la peinture qu'il traduit ses premières impressions du monde.</p>
                {isMobile
                    ? <ShowMore title="Découvrir sa biographie en détail..." ><div className="flex flex-col gap-5 text-justify mb-32">{biography}</div></ShowMore>
                    : biography
                }
            </div>
            <div className="mb-10">
                <div className="flex flex-row gap-8">
                    <div className="sm:w-40"><Image className="w-full" name="static/29f2f1d5-9456-4f2b-b310-f54ca36b74af.jpg" alt="Jacques Courtens" /> </div>
                    <div className="flex-1 flex flex-col justify-center">
                        <div className="hidden sm:block w-96"><Logo /></div>
                        <cite>"J'ai cherché le fil entre la vie et la mort, le souvenir d'une image et non l'image elle-même". J.C</cite>
                    </div>
                </div>
            </div>
            <Image className="mb-6 sm:mb-10" name="static/b2591ac6-43e4-4bd0-a0e0-e73604415a8d.jpg" />
            <Image name="static/eb6cdb4a-24ba-44cd-875d-096eb8a76e9a.jpg" />
        </>
    )
}

function BandeauSection({ id, className, image, title, citation }) {
    return (
        <div id={id} className={`bg-courtens-gray text-courtens-lightgray flex flex-col gap-4 sm:flex-row sm:justify-between sm:items-center px-8 py-16 ${className && className}`}>
            <div className="flex-1 flex gap-5">
                <div className="w-28 sm:w-60"><Image name={image} /></div>
                <div className="flex-1 flex flex-col justify-end">
                    <h1 className="uppercase bold">
                        <span className="text-5xl sm:text-7xl">{title.substring(0, 1)}</span>
                        <span className="sm:text-5xl">{title.substring(1)}</span>
                    </h1>
                    <cite className="hidden sm:block text-lg">{citation}</cite>
                </div>
            </div>
            <cite className="sm:hidden max-w-lg">{citation}</cite>
            <div className="hidden sm:block w-80"><Logo /></div>
        </div>
    )
}

function RealisationsSection() {
    const [thematiques, setThematiques] = useState([])

    useEffect(() => {
        getThematiques().then(setThematiques)
    }, [])

    return (
        <>
            <BandeauSection id="oeuvres" className="mb-20" image="static/778b797e-8d88-42f5-946e-09cb50c979bc.jpg" title="œuvres" citation="« la poésie n'existe que lorsque l'homme marche sur les nuages ». JC" />
            <div style={{ color: "#4E5046" }} className="flex flex-col mb-20 text-center text-lg italic font-semibold">
                <p>Les couleurs utilisées par Jacques Courtens sont à base de pigments naturels<br />acquis à Scheveningen Pays Bas, fournisseur du musée du Louvre</p>
            </div>
            <div className="grid grid-flow-row grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-12">
                {thematiques.map(thematique => <ThematiqueTile key={thematique._id} thematique={thematique} />)}
                <div className="col-span-2"><iframe className="w-full sm:h-full h-72" src="https://www.youtube.com/embed/7hMw9sjeMQE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
            </div>
        </>
    )
}

function ThematiqueTile({ thematique }) {
    return (
        <Link to={`/thematiques/${thematique._id}`}>
            <div className="group">
                <div className="flex flex-col w-full">
                    <ImageOverlay className="aspect-w-3 aspect-h-4 w-full " title="Découvrir...">
                        <Image className={`object-cover ${thematique.thumbnail.isDessin && 'border border-gray-200'}`} name={thematique.thumbnail.src} />
                    </ImageOverlay>
                    <div style={{ color: "#4E5046" }} className="text-center text-lg font-semibold mt-2">
                        {thematique.isTheme && (<>Thème <br className="sm:hidden" /></>)}« <span className="uppercase">{thematique.name}</span> »
                    </div>
                </div>
            </div>
        </Link>
    )
}

// function VisibiliteSection() {
//     return (
//         <>
//             <BandeauSection id="visibilite" image="static/778b797e-8d88-42f5-946e-09cb50c979bc.jpg" title="visibilité" citation="« La pensée est un tout indivisible et l'art, sa contribution, 
// a pour mission de transmettre des élévations profondes de sensations et d'éternité. » JC" />
//         </>
//     )
// }

function MediaSection() {
    const quotes = [
        {
            name: 'Olivier CLYNCKEMAILLIE',
            title: 'Conservateur du Musée des Beaux-Arts de Mouscron',
            children: (
                <p>(…) À l'instar d'un livre d'images de notre enfance, les œuvres de Jacques Courtens se présentent comme l'album souve­nir de nos rêves les plus fous (…)<br />Le geste est sûr de lui tout en restant divinement aérien. La ligne claire surprend, soumet et élève. Les figurants y acquièrent puissance et candeur à la fois (…)</p>
            )
        },
        {
            name: 'Alain Decaux',
            title: 'Académicien, Historien, Ministre de la Francophonie',
            children: (
                <p>C'est « un privilège de découvrir les étonnantes créations nées d'un pinceau inspiré, tourbillon étourdissant de formes oniriques et de couleurs qui n'appartiennent qu'a lui »</p>
            )
        },
        {
            name: 'M. Duchateau',
            title: 'Conservateur du Patrimoine Culturel du Palais de la Nation, Belgique',
            children: (
                <p>(…) Le monde dans lequel vit Jacques Courtens, et qu'il représente, exprime dans ses œuvres, est celui qui fut déjà illustré par les grands maitres de l'époque du Rococo. Je pense à Fragonard et, en premier lieu, à Boucher qui, dans leurs toiles souvent polissonnes, ramènent le spectateur à l'innocence primordiale (…)</p>
            )
        }
    ]

    return (
        <>
            <BandeauSection id="medias" className="mb-14" image="static/778b797e-8d88-42f5-946e-09cb50c979bc.jpg" title="médias" citation="« Les mots qui quittent le livre sont l'essentiel de la pensée... » JC" />
            <div className="flex flex-col sm:flex-row gap-20 mb-20">
                {quotes.map((q, i) => <div key={i} className="flex-1"><Quote name={q.name} title={q.title}>{q.children}</Quote></div>)}
            </div>
            <div id="media-text" className="flex-1">
                <HomePress />
                <HomeBibliographie />
                <HomeFilmographie />
                <HomeYoutube />
                <HomeExpositions />
            </div>
        </>
    )
}

function Quote({ name, title, children }) {
    return (
        <figure>
            <blockquote className="mb-4 sm:mb-8"><cite>{children}</cite></blockquote>
            <figcaption>
                <p><strong>{name}</strong><br /><span className="text-sm">{title}</span></p>
            </figcaption>
        </figure>
    )
}

function HomePress() {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [articles, setArticles] = useState(undefined)

    useEffect(() => {
        getPress().then(res => setArticles(res.articles))
    }, [])

    return (
        <>
            <h2>Presse</h2>
            {articles === undefined
                ? 'Chargement...'
                : <ImageCarousel images={articles} selectedIndex={selectedIndex} onSelect={setSelectedIndex} />
            }

        </>
    )
}

function HomeBibliographie() {
    const [selectedBook, setSelectedBook] = useState(undefined)
    const isMobile = useIsMobile()
    const [isStart, setIsStart] = useState(true)
    const [isEnd, setIsEnd] = useState(false)
    const scrollRef = useRef()

    const books = [
        'books/8e51ec72-0c63-4ed9-aab2-917fe104c6b0.jpg',
        'books/dd7acfdc-cbdc-41eb-8540-4fcab36058c8.jpg',
        'books/14e2b87c-bd54-4eff-9897-83baf253018a.jpg',
        'books/fe1ed6fa-a117-40b2-bd37-a1f97f403c45.jpg',
        'books/33d6b53f-dc9e-48f5-bb1e-3f8aa5487532.jpg',
        'books/087ebd9d-dd01-4d7e-8175-6b2f111ee9ad.jpg',
    ]

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                console.log('intersecting')
                scrollRef.current.scrollTo({ left: scrollRef.current.getBoundingClientRect().width / 4, behavior: 'smooth' })
                observer.unobserve(scrollRef.current)
            }
        }, { threshold: .65 })
        observer.observe(scrollRef.current)
    }, [])

    function imageListScroll(e) {
        const threshold = 20
        const el = e.target
        setIsStart(el.scrollLeft <= threshold)
        setIsEnd(el.scrollLeft + el.getBoundingClientRect().width >= el.scrollWidth - threshold)
    }

    return (
        <>
            <h2>Bibliographie sélective et catalogues d’expositions</h2>
            <div className="grid grid-cols-1 sm:grid-cols-expos sm:gap-x-10 sm:gap-y-2 mb-10">
                <span className="font-semibold sm:max-w-xs">« De Breughel à nos jours »</span><span className="mb-5 sm:mb-0">Salons de la Malmaison 1984 – 1985 Cannes, Arts Graphiques Cannes</span>
                <span className="font-semibold sm:max-w-xs">« Masques d’artistes »</span><span className="mb-5 sm:mb-0">Salons de la Malmaison 1987 Cannes</span>
                <span className="font-semibold sm:max-w-xs">« Jacques Courtens – Rétrospective. Peintures, dessins »</span><span className="mb-5 sm:mb-0">Palais Provincial du Brabant et Hôtel de Ville de Bruxelles. Ed. Snoeck Ducaju en Zoon – Gent 1992.</span>
                <span className="font-semibold sm:max-w-xs">« L’Univers de Jacques Courtens. »</span><span className="mb-5 sm:mb-0">Essai sur les dessins par Aurélie Dandoy. Ed. D.C.G., Vallauris, 1993.</span>
                <span className="font-semibold sm:max-w-xs">« Jacques Courtens Et in Arcadia ego… »</span><span className="mb-5 sm:mb-0">Olivier Clynckemaillie, Ed. Musée des Beaux-Arts de Mouscron, 2001</span>
                <span className="font-semibold sm:max-w-xs">« Que le rêve demeure! Vie et œuvre d’un peintre-poète, Jacques Courtens »</span><span className="mb-5 sm:mb-0">Isabelle Courtens, Ed. D.C.G., Vallauris, 2005, (ISBN 2-916118-00-4)</span>
                <span className="font-semibold sm:max-w-xs">« La Rose l’Aventurier et le Rêveur »</span><span className="mb-5 sm:mb-0">Victor Varjac, Ed. D.C.G. Vallauris, 2007, (ISBN 2-916118-02-0)</span>
                <span className="font-semibold sm:max-w-xs">« Jacques Courtens, ballade onirique au royaume d’Ether »</span><span className="mb-5 sm:mb-0"> Ed. Mairie de Gannat, 2016</span>
            </div>
            <div className="relative w-full">
                <div className={`${(!isMobile || isStart) ? "opacity-0" : "opacity-100"} absolute top-0 bottom-0 left-0 w-8 z-10 bg-gradient-to-r from-white to-transparent transition-opacity pointer-events-none`}></div>
                <div className={`${(!isMobile || isEnd) ? "opacity-0" : "opacity-100"} absolute top-0 bottom-0 right-0 w-8 z-10 bg-gradient-to-l from-white to-transparent transition-opacity pointer-events-none`}></div>
                <div ref={scrollRef} onScroll={imageListScroll} className="h-48 flex flex-nowrap overflow-x-auto gap-5 mt-2">
                    {books.map(url => (
                        <ImageOverlay key={url} className="flex-shrink-0 h-full w-max cursor-pointer" onZoom={() => setSelectedBook(url)}>
                            <Image className="h-full w-auto" name={url} />
                        </ImageOverlay>
                    ))}
                </div>
            </div>


            <ImageViewerModal
                isOpen={selectedBook !== undefined}
                onRequestClose={() => setSelectedBook(undefined)}
            >
                <Image className="h-full object-contain" name={selectedBook} alt="Image plein écran" />
            </ImageViewerModal>
        </>
    )
}

function HomeFilmographie() {
    return (
        <>
            <h2>Filmographie</h2>
            <div className="grid grid-cols-1 sm:grid-cols-expos sm:gap-x-10">
                <span><strong>1981</strong> janvier</span><span className="mb-5 sm:mb-0">Reportage Pierre Cazenave et Georges Giaufret, <strong className="font-semibold">TV Monte-Carlo</strong>.</span>
                <span><strong>1991</strong> mars</span><span className="mb-5 sm:mb-0">« La Mort Joyeuse ». D. Constantini, Ch. Loubet, 10 min. – <strong className="font-semibold">TV Nice</strong>.</span>
                <span><strong>1992</strong> décembre</span><span className="mb-5 sm:mb-0">« L’Univers de Jacques Courtens » par Amnouel Grigoriy <strong className="font-semibold">Evro Ask Film, Moscou</strong>, 26 min.</span>
                <span><strong>1993</strong> avril</span><span className="mb-5 sm:mb-0">« Histoires de Bruxelles » : « La dynastie des Courtens » Jo Gérard, 12 min. – <strong className="font-semibold">Télévision BXL</strong>. 3 mars 1993.</span>
                <span><strong>1992</strong></span><span className="mb-5 sm:mb-0">Reportage Expo Bruxelles Hôtel de Ville et Palais du Gouverneur – Film Vernissage – <strong className="font-semibold">RTBF</strong>.</span>
                <span><strong>1998</strong> août</span><span className="mb-5 sm:mb-0">Reportage H.Migout – <strong className="font-semibold">FR3 Côte d’Azur</strong></span>
                <span><strong>1998</strong> août</span><span className="mb-5 sm:mb-0">« Sud Magazine » reportage Maguelone Hedon, 10 min. – <strong className="font-semibold">T.M.C. Monte Carlo</strong></span>
            </div>
        </>
    )
}

function HomeYoutube() {
    return (
        <>
            <h2>Reportages en ligne</h2>
            <div className="flex flex-col sm:flex-row gap-8">
                <iframe className="h-72 w-full sm:w-96 sm:h-96" src="https://www.youtube-nocookie.com/embed/2NMeokXOL8s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe className="h-72 w-full sm:w-96 sm:h-96" src="https://www.youtube-nocookie.com/embed/FvOknvUgjVM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </>
    )
}

function HomeExpositions() {
    return (
        <>
            <h2>Expositions</h2>
            <div className="grid grid-cols-1 sm:grid-cols-expos sm:gap-x-10">
                <span><strong>1947</strong></span><span className="mb-5 sm:mb-0">Les Frères Suros (Pierre et Jacques Courtens), galerie Pierre-Maur, <strong className="font-semibold">av. Montaigne, Paris</strong></span>
                <span><strong>1979</strong> octobre</span><span className="mb-5 sm:mb-0">Kredietbank S.A., <strong className="font-semibold">Luxembourg</strong></span>
                <span><strong>1980</strong> juin</span><span className="mb-5 sm:mb-0">Musée Léon de Smet, Deurle, <strong className="font-semibold">Gand, Belgique</strong></span>
                <span><strong>1981</strong> octobre</span><span className="mb-5 sm:mb-0">Faculty Club, <strong className="font-semibold">Louvain, Belgique</strong></span>
                <span><strong>1982</strong> mars</span><span className="mb-5 sm:mb-0">Centre International, <strong className="font-semibold">Grasse, France</strong></span>
                <span><strong>1984</strong> décembre – janvier <strong>1985</strong></span><span className="mb-5 sm:mb-0">« De Breughel à nos jours », Malmaison, <strong className="font-semibold">Cannes, France</strong></span>
                <span><strong>1987</strong> mars – juin</span><span className="mb-5 sm:mb-0">« Masques d’artistes », Malmaison, <strong className="font-semibold">Cannes, France</strong></span>
                <span><strong>1987</strong> avril</span><span className="mb-5 sm:mb-0">Syndicat d’initiative, <strong className="font-semibold">Biot, France</strong></span>
                <span><strong>1988</strong> juillet</span><span className="mb-5 sm:mb-0">Grand Prix Européen des Arts et des Lettres (Invité d’honneur) Centre Universitaire Méditerranéen, <strong className="font-semibold">Nice, France</strong></span>
                <span><strong>1990</strong> juin</span><span className="mb-5 sm:mb-0">Grand Prix Européen des Arts et des Lettres (Invité d’honneur) Palais des Congrès, <strong className="font-semibold">Grasse, France</strong></span>
                <span><strong>1992</strong> février</span><span className="mb-5 sm:mb-0"><strong className="font-semibold">Sous le haut patronage de Sa Majesté la Reine Fabiola</strong>, « Rétrospective Jacques Courtens », Palais provincial du Brabant et <strong className="font-semibold">Hôtel de Ville Bruxelles, Belgique</strong></span>
                <span><strong>1993</strong> juillet – septembre</span><span className="mb-5 sm:mb-0">« Rétrospective », Palais des Congrès et Hommage à Igor Markevitch <strong className="font-semibold">Villa-Musée Fragonard, Grasse, France</strong></span>
                <span><strong>1993</strong> septembre – octobre</span><span className="mb-5 sm:mb-0">« Salon des Peintres du XVIe », <strong className="font-semibold">Mairie du XVIe, Paris, France</strong></span>
                <span><strong>1998</strong> août – septembre</span><span className="mb-5 sm:mb-0">Dans le cadre du 90e anniversaire de Cap d’Ail « Hommage à Jacques Courtens », Exposition Office du tourisme et Médiathèque de Cap d’Ail, <strong className="font-semibold">Cap d’Ail, France</strong></span>
                <span><strong>1998</strong> septembre – octobre</span><span className="mb-5 sm:mb-0">Casino Rhul – Salle des jeux, <strong className="font-semibold">Nice, France</strong></span>
                <span><strong>2001</strong> mars – mai</span><span className="mb-5 sm:mb-0">« Rétrospective Jacques Courtens », Centre Culturel Marius Staquet, <strong className="font-semibold">Mouscron, Belgique</strong></span>
                <span><strong>2008</strong> mai – juin</span><span className="mb-5 sm:mb-0">Musée d’Art et d’Histoire de Provence, <strong className="font-semibold">Grasse, France</strong></span>
                <span><strong>2008</strong> juin – juillet</span><span className="mb-5 sm:mb-0">« 20e anniversaire de la disparition du peintre Jacques Courtens », exposition organisée par la Ville de Grasse, Palais des Congrès de <strong className="font-semibold">Grasse, France</strong></span>
                <span><strong>2009</strong> mars – avril</span><span className="mb-5 sm:mb-0">Mairie de <strong className="font-semibold">Bonson, France</strong></span>
                <span><strong>2010</strong> juillet</span><span className="mb-5 sm:mb-0">Salle des Meurtrières, Mairie de <strong className="font-semibold">Saint-Cézaire-sur-Siagne, France</strong></span>
                <span><strong>2011</strong> janvier</span><span className="mb-5 sm:mb-0">Dans le cadre du Festival international du cirque de Monte-Carlo, La Maison de l’Amérique Latine présente « Le Cirque » de Jacques Courtens, <strong className="font-semibold">Monte-Carlo, Monaco</strong></span>
                <span><strong>2011</strong> février – mars</span><span className="mb-5 sm:mb-0">Affiche du concours de La Poste « Rencontre en toutes lettres » et exposition à la Médiathèque de Noailles, <strong className="font-semibold">Cannes, France</strong></span>
                <span><strong>2012</strong> septembre – octobre</span><span className="mb-5 sm:mb-0">« Amour et Musique » – Château des Terrasses, <strong className="font-semibold">Cap d’Ail, France</strong></span>
                <span><strong>2014</strong> janvier – février</span><span className="mb-5 sm:mb-0"> « Hymne à la Vie » Salle du Polygone, Chevigny-Saint-Sauveur, <strong className="font-semibold">Dijon, France</strong></span>
                <span><strong>2016</strong> juin – août</span><span className="mb-5 sm:mb-0">« Ballade Onirique au Royaume d’Éther » Château Musée Yves Machelon et Pavillon Delarue, <strong className="font-semibold">Gannat, France</strong></span>
            </div>
        </>
    )
}