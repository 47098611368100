import axios from 'axios'

const API_URL = window.location.origin


export async function getThematiques() {
    return (await axios.get(API_URL + '/database.json')).data
}

export async function getThematique(_id) {
    const thematiques = await getThematiques()
    return thematiques.find(t => t._id === _id)
}