import React from 'react'
import Image from './Image'

export default function ImageOverlay({ style, children, title, onZoom, className }) {
    return (
        <div style={style} className={`${className} relative group`} onClick={onZoom}>
            {children}
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-3xl font-semibold text-white backdrop-filter backdrop-blur-sm backdrop-brightness-50 opacity-0 group-hover:opacity-100 transition">
                {title}
                {onZoom && <Image className="w-12" name="search.svg" local />}
            </div>
        </div>
    )
}
