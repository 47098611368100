import Navbar from "../Components/Navbar"



export default function Page({ children }) {
    return (
        <div className="w-11/12 2xl:w-4/6 mx-auto">
            <Navbar />
            {children}
        </div>
    )
}