import React from 'react'
import { useIsMobile } from '../hooks'
import Image from './Image'
import ImageSlider from './ImageSlider'

export default function ImageDiapo({ images, index, onChange }) {
    const isMobile = useIsMobile()

    return (
        <div style={{ width: isMobile ? '100%' : '80vw' }} className="h-full relative">
            <div className="hidden sm:block absolute transform top-1/2 -translate-y-1/2 left-0 -translate-x-full cursor-pointer filter invert" onClick={() => onChange(index - 1)}>
                <Image className="transform rotate-90 w-20" name="chevron_down.svg" local />
            </div>
            {
                isMobile
                    ? <ImageSlider images={images} selected={images[index]} />
                    : <div className="w-full h-full overflow-y-auto">
                        <Image className="w-full object-contain" name={images[index]} />
                    </div>
            }
            <div className="hidden sm:block absolute transform top-1/2 -translate-y-1/2 right-0 translate-x-full cursor-pointer filter invert" onClick={() => onChange(index + 1)}>
                <Image className="transform -rotate-90 w-20" name="chevron_down.svg" local />
            </div>
        </div>
    )
}
