import React, { useState } from 'react'
import Image from './Image'

export default function ShowMore({ title, className, children, style }) {
    const [showMore, setShowMore] = useState(false)

    return (
        <div>
            <div onClick={() => setShowMore(!showMore)} style={style} className={`flex justify-between sm:justify-start sm:gap-8 cursor-pointer ${className ?? ''}`}>
                <h3 className="my-4 group font-semibold">{title}</h3>
                <Image className={`transform ${showMore ? 'rotate-180' : ''}`} name="chevron_down.svg" local />
            </div>
            {showMore && children}
        </div>
    )
}
