import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { getThematique } from '../Api/thematiques'
import Image from "../Components/Image"
import Page from "../Components/Page"
import { useMediaQuery } from "../hooks"
import ImageOverlay from "../Components/ImageOverlay"
import ImageViewerModal from "../Components/ImageViewerModal"
import ImageSlider from "../Components/ImageSlider"

export default function ThematiquePage() {
    const { _id } = useParams()
    const [thematique, setThematique] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [selectedImage, setSelectedImage] = useState(undefined)
    const isMobile = useMediaQuery('(max-width: 500px)')

    useEffect(() => {
        if (_id === undefined) return

        getThematique(_id).then((t) => {
            setThematique(t)
            setLoading(false)
        })
    }, [_id])

    return (
        <div>
            <Page>
                {loading
                    ? <div className="w-full h-full flex justify-center items-center">Chargement...</div>
                    : thematique === undefined
                        ? <div className="w-full h-full flex justify-center items-center">Erreur...</div>
                        : <>
                            <h1 className="my-8 mt-40 mb-32">{thematique.isTheme && 'Thème '}« <span className="uppercase">{thematique.name}</span> »</h1>
                            {/* <p className="pl-8 sm:pl-12 mb-24">{thematique.description}</p> */}
                            <div className="mb-96 grid grid-cols-4 gap-4 sm:grid-cols-6 sm:gap-12">
                                {isMobile && thematique.mobileGallery !== undefined
                                    ? thematique.mobileGallery.map((imageInfo) => <ImageTile key={imageInfo.src} imageInfo={imageInfo} onClick={() => setSelectedImage(imageInfo.src)} />)
                                    : thematique.gallery.map((imageInfo) => <ImageTile key={imageInfo.src} imageInfo={imageInfo} onClick={() => setSelectedImage(imageInfo.src)} />)
                                }
                            </div>
                        </>
                }
            </Page>
            <ImageViewerModal
                isOpen={selectedImage !== undefined}
                onRequestClose={() => setSelectedImage(undefined)}
            >
                {isMobile
                    ? <ImageSlider images={
                        (thematique?.mobileGallery !== undefined
                         ? thematique?.mobileGallery
                         : thematique?.gallery)?.map(im => im.src)
                    } selected={selectedImage} />
                    :
                    <Image className="h-full object-contain" name={selectedImage} alt="Image plein écran" />
                }
            </ImageViewerModal>
        </div>
    )
}

function ImageTile({ imageInfo, onClick }) {
    // Image must be both stretch and contained for contained to work !!
    const { src, size, isDessin, align, "object-fit": objectFit = 'cover' } = imageInfo

    const sizes = {
        md: 'col-span-2',
        lg: 'col-span-4 sm:col-span-3',
        xl: 'col-span-full',
    }

    const alignments = {
        bottom: 'top-auto',
        center: 'top-1/2 transform -translate-y-1/2',
        top: ''
    }

    const fits = {
        contain: 'object-contain',
        cover: 'object-cover',
        fill: 'object-fill',
    }

    return (
        <div className={`cursor-pointer overflow-hidden ${isDessin && 'border border-gray-200'} ${sizes[size]}`}>
            <ImageOverlay className={`${size === 'md' ? 'aspect-w-3 aspect-h-4' : 'aspect-w-4 aspect-h-3'}`} onZoom={onClick}>
                <Image className={`${align !== undefined ? alignments[align] + ' h-auto' : fits[objectFit]}`} name={src} />
            </ImageOverlay>
        </div>
    )
}