import React, { useState } from 'react'
import { useIsMobile } from '../hooks'
import Image from './Image'
import ImageDiapo from './ImageDiapo'
import ImageOverlay from './ImageOverlay'
import ImageViewerModal from './ImageViewerModal'
import ShowMore from './ShowMore'

export default function ImageCarousel({ images, selectedIndex, onSelect }) {
    const [fullscreen, setFullscreen] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const isMobile = useIsMobile()

    function selectImage(index) {
        if (index < 0) index = images.length - 1
        if (index >= images.length) index = 0
        setFullscreen(true)
        onSelect(index)
    }

    const gallery = (
        <div className="grid grid-cols-2 sm:grid-cols-4 4k:grid-cols-6">
            {images.map((im, i) => (
                <div key={im} className="w-full flex justify-center align-center">
                    <ImageOverlay className="cursor-pointer" onZoom={() => selectImage(i)}>
                        <Image className="h-48 mx-auto" name={im} alt="Article de presse" />
                    </ImageOverlay>
                </div>
            ))}
        </div>
    )

    return (
        <>
            <div className="flex justify-start gap-8 sm:mb-8">
                <ImageOverlay style={{ height: 'min-content' }} className="max-w-3xl cursor-pointer flex justify-center items-center" onZoom={() => selectImage(selectedIndex)}>
                    <Image className="w-full h-auto object-contain border border-gray-200" name={images[selectedIndex]} />
                </ImageOverlay>
                <div className="hidden sm:flex items-center">
                    <div onClick={() => setShowMore(!showMore)} className="bg-courtens-gray text-courtens-lightgray mx-auto w-40 p-2 text-center rounded-md cursor-pointer transition">Toute la presse</div>
                </div>
            </div>
            {isMobile
                ? <ShowMore title="Toute la presse">{gallery}</ShowMore>
                : showMore && gallery
            }
            <ImageViewerModal
                isOpen={fullscreen}
                onRequestClose={() => setFullscreen(false)}
            >
                <ImageDiapo images={images} index={selectedIndex} onChange={selectImage} />
            </ImageViewerModal>
        </>
    )
}
